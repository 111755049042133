import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";


import AuthService from "../../services/auth.service";
import logo from '../images/logo.png'


const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };



function Login(props) {

  const form = useRef();
    const checkBtn = useRef();
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
   // const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
  
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };
  
    const handleLogin = (e) => {
      e.preventDefault();
  
      setMessage("");
     // setLoading(true);
  
      form.current.validateAll();
  
      if (checkBtn.current.context._errors.length === 0) {
        AuthService.login(username, password).then(
          () => {
            props.history.push("/home");
            window.location.reload();
          },
          (error) => {
            const resMessage =   (error.response &&  error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
             
             // setLoading(false);
  
              if(error.message === "Network Error"){
  
                setMessage("No data connection");
             
  
              }else{
                setMessage("Incorrect Username or Password");
              }
  
            
            
          }
        );
      } else {
       // setLoading(false);
      }
    };
  



  return (
    <div className="body">

      <div className="login">



        <div className="wrapper fadeInDown">
          <div id="formContent">
            {/* <!-- Tabs Titles --> */}
            <h2 className="active"> Golden Furniture</h2>
            

            {/* <!-- Icon --> */}
            <div className="fadeIn first">
               
              <img src={logo} id="icon" alt="User Icon" className="logo-img"/>
              
              
            </div>

            {/* <!-- Login Form --> */}
            <Form className="login-form" onSubmit={handleLogin} ref={form}>
              <Input 
               type="text"
               id="username" 
               className="fadeIn second" 
               name="username" 
               autoComplete="off"
               placeholder="username" 
               value={username}
               onChange={onChangeUsername}
              validations={[required]} />

              <Input 
               type="password"
               id="password"
               className="fadeIn third"
               name="password" 
               placeholder="password"
               value={password}
              onChange={onChangePassword}
              validations={[required]}
                />
              
              <button type="submit" className="login-button"> <div > <span className="fadeIn fourth"></span>Login</div></button>

              {/* <!-- Remind Passowrd --> */}
            <div id="formFooter">
            {message && (
            <div className="form-group">
              <div className="danger-message" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
              
            </div>
            </Form>

            

          </div>
        </div>

      </div>
    </div>
  );
}

export default Login;