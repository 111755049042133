import React, { useState, useEffect, useMemo, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices, FcCustomerSupport } from 'react-icons/fc';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { AiFillFolderOpen, AiOutlinePhone } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import MemberService from "../../services/members.service";



const currentUser = AuthService.getCurrentUser();


const CustomersList = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_SALER")) {
    return <Redirect to="/unauthorized" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  const initialDataState = {
    id: null,
    fullname: "",
    phone: "",
    contact: "",
    phone: "",
    email: "",
    other: "",
    remark: "",
    username: "",

    published: false
  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const saveData = () => {

    setPage(1);

    var data = {
      code: tutorial.code,
      name: tutorial.name,
      description: tutorial.description,
      min_price: tutorial.min_price,
      max_price: tutorial.max_price,
      color: tutorial.color,
      size: tutorial.size,
      remark: tutorial.remark,
      username: currentUser.username
    };



    CustomersService.create(data)
      .then(response => {
        setData({
          id: response.data.id,
          code: response.data.code,
          name: response.data.name,
          description: response.data.description,
          min_price: response.data.min_price,
          max_price: response.data.max_price,
          color: response.data.color,
          size: response.data.size,
          remark: response.data.remark,
          code: response.data.code,
          username: response.data.username

        });
        setSubmitted(true);
        //console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };

  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchTitleType, setSearchTitleType] = useState("Name");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 300, 500];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };




  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize, searchTitleType);

    CustomersService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        getMemberData();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }


  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const handleSearch = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const handleSearchPhone = () => {
    setPage(1);

    setSearchTitleType("Phone");

    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setPage(1);

    //const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/customer/" + id);
  };

  const updateData = () => {
    CustomersService.update(currentData.id, currentData)
      .then(response => {
        // console.log(response.data);
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteData = (rowIndex) => {
    const id = tutorialsRef.current[rowIndex].id;

    CustomersService.remove(id)
      .then((response) => {
        /// props.history.push("/product");

        let newDatas = [...tutorialsRef.current];
        newDatas.splice(rowIndex, 1);

        setDatas(newDatas);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const add = () => {
    props.history.push("/customers-new");
  }


  return (
    <div className='pages'>

      <div className="row">
        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table-tools">
            <div className="table-account">
            <tbody>
              <tr>
                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>{memberdetails.firstname}</td>
                <td>{memberdetails.lastname}</td>
              </tr>
              <tr>
                <td>Branch:</td>
                <td>{memberdetails.account}</td>
              </tr>

              <tr>
                <td colSpan="2"> <input type="text" value={searchTitle}
                  onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                <td><button onClick={handleSearch} className="button-tools-sm"> <span className="table-tools-img-search"><BsPersonBoundingBox /></span></button><button onClick={handleSearchPhone} className="button-tools-sm"> <span className="table-tools-img-search"><AiOutlinePhone /></span></button></td>
              </tr>
            </tbody>
            </div>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table>
            <tbody>
              <tr>
                <td><span className="page-title-img"><FcCustomerSupport /> </span></td>
                <td><span className="page-title"> Customer</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-3 tool-box">

          <table className="table-tools">
            <tbody>
              <tr>
                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                <td className="tg-0lax"></td>
                <td className="tg-0lax"></td>
              </tr>
              <tr>
                <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
              </tr>
              <tr>
                <td className="tg-0lax"></td>
                <td className="tg-0lax"> </td>
              </tr>
            </tbody>

          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">
          <div className="row pagination-box">

            <div className="col-md-3 pagination-box">{"Items per Page : "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select></div>

            <div className="col-md-3 table-title">  CUSTOMERS LIST  </div>

            <div className="col-md-4"><Pagination

              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />

            </div>



          </div>
          <div className="details">

            <div className="table-wrap">

              <table className="table ">
                <thead className="scroll-thead">
                  <tr>
                    <th>ID</th>
                    <th>FULL NAME</th>
                    <th>CONTACT</th>
                    <th>PHONE</th>
                    <th>EMAIL</th>
                    <th>OTHER</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr key={tutorial.id}>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.fullname}</td>
                        <td>{tutorial.contact}</td>
                        <td>{tutorial.phone}</td>
                        <td>{tutorial.email}</td>
                        <td>{tutorial.other}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiFillFolderOpen />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
});

export default CustomersList;
