import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';

import { GiTimeBomb } from 'react-icons/gi';
import { MdTransform } from 'react-icons/md';
import { AiTwotoneEdit, AiFillDelete } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";

import SelectOptionService from "../../services/select-options.service";

import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();


const StockListLevel = withRouter((props) => {

    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_STOCK")) {
        return <Redirect to="/unauthorized" />;

    }

    const initialDataState = {
        id: null,
        stand: "",
        date1: "",
        pid: "",
        product: "",
        qty_in: "",
        stockkeeper: "",
        code: "",

        size: "",
        color: "",
        qty_out: "",
        qty_transfer_in: "",
        qty_transfer_out: "",
        qty_damage: "",
        stand_destination: "",
        stand_source: "",
        description: "",
        comment: "",
        transfer_type: "",
        cbm: "",
        kg: "",
        packaging: "",




        username: "",

        published: false,

    };



    const [tutorial, setData] = useState(initialDataState);
    //const [submitted, setSubmitted] = useState(false);
    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");

    const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
    const [optionCategorySelectedValueProduct, setOptionCategorySelectedValueProduct] = useState("");
    const [optionCategorySelectedValueDescription, setOptionCategorySelectedValueDescription] = useState("");
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);
    const [optionCategoryArrayProduct, setOptionCategoryArrayProduct] = useState([]);

    const [stockName, setStockName] = useState("");
    const [isManager, setIsManager] = useState(false);

    const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
    const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);
    const [memberdetails, setMemberDetails] = useState([]);

    const handleChangeoptionCategories = (event) => {
        setPage(1);
        setOptionCategorySelectedValue(event.target.value);
        setStockName(event.target.value);

        // retrieveDataBystock();

    }

    const handleChangeoptionDescription = (event) => {
        setPage(1);
        setOptionCategorySelectedValueDescription(event.target.value);

        // retrieveDataBystock();

    }

    const handleChangeoptionCategoriesProduct = (event) => {
        setPage(1);
        setOptionCategorySelectedValueProduct(event.target.value);

    }

    const handleChangeoptionCategoriesTransfer = (event) => {
        setOptionCategorySelectedValueTransfer(event.target.value);

    }


    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const saveData = () => {

        setPage(1);

        var data = {
            stand: optionCategorySelectedValue,
            date1: tutorial.date1,
            pid: searchedProductDetails.pid,
            product: searchedProductDetails.product,
            qty_in: tutorial.qty_in,
            stockkeeper: currentUser.username,
            code: searchedProductDetails.code,
            size: searchedProductDetails.size,
            qty_out: tutorial.qty_out,
            qty_transfer_in: tutorial.qty_transfer_in,
            qty_transfer_out: tutorial.qty_transfer_out,
            qty_damage: tutorial.qty_damage,
            stand_destination: tutorial.stand_destination,
            stand_source: tutorial.stand_source,
            description: tutorial.description,
            comment: tutorial.comment,
            transfer_type: optionCategorySelectedValueTransfer
        };


        if (data.product == null || data.product == "") {
            setMessage("Product details are missing!");
        } else if (data.stand == null || data.stand == "" || data.stand == "Select") {
            setMessage("Select valid stock or stand!");
        } else if (data.transfer_type == null || data.transfer_type == "" || data.transfer_type == "Select") {
            setMessage("Select valid Transfer type or stand!");
        }
        else {

            StockService.create(data)
                .then(response => {
                    setData({
                        stand: response.data.stand,
                        date1: response.data.date1,
                        pid: response.data.pid,
                        product: response.data.product,
                        qty_in: response.data.qty_in,
                        stockkeeper: response.data.stockkeeper,
                        code: response.data.code,
                        size: response.data.size,
                        qty_out: response.data.qty_out,
                        qty_transfer_in: response.data.qty_transfer_in,
                        qty_transfer_out: response.data.qty_transfer_out,
                        qty_damage: response.data.qty_damage,
                        stand_destination: response.data.stand_destination,
                        stand_source: response.data.stand_source,
                        description: response.data.description,
                        comment: response.data.comment,
                        transfer_type: response.data.transfer_type

                    });
                    //setSubmitted(true);
                    //console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });

        }


    };



    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [searchProduct, setSearchProduct] = useState("");
    const [searchedProductDetails, setSearchedProductDetails] = useState("");
    const [optionCategoryArrayDescription, setOptionCategoryArrayDescription] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);



    const pageSizes = [100, 500, 1000];

    tutorialsRef.current = tutorials;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };

    const onChangeSearchProduct = (e) => {
        const searchProduct = e.target.value;
        setSearchProduct(searchProduct);

    };



    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        if (optionCategorySelectedValue) {
            params["optionCategorySelectedValue"] = optionCategorySelectedValue;
        }

        return params;
    };

    const retrieveData = () => {


        // setPage(1);
        // const params = getRequestParams(searchTitle, page, pageSize,optionCategorySelectedValue);

        // StockService.getAllStockLevel(params)
        // .then((response) => {
        // const { tutorials, totalPages } = response.data;

        // setDatas(tutorials);
        // setCount(totalPages);

        // //console.log(response.data);
        // })
        // .catch((e) => {
        // console.log(e);
        // });
        getSelectOption();
        getSelectOptionTransfer();
        getMemberData();
    };

    const getMemberData = () => {

        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setIsManager(true);

        }

    }

    const retrieveDataBystock = () => {

        setPage(1);

        const params = getRequestParams(searchTitle, page, pageSize, optionCategorySelectedValue);

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setStockName(optionCategorySelectedValue);

        } else {
            setStockName(memberdetails.account);
        }


        var data = {
            text1: stockName,
            text2: optionCategorySelectedValueDescription
        }

        //StockService.getAllStockLevelBystock(params)
        StockService.getAllStockLevelBystockByDescription(data)
            .then((response) => {
                // const { tutorials, totages } = response.data;

                setDatas(response.data);
                //setCount(totalPages);

                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        getSelectOption();
        getSelectOptionTransfer();
    };



    const getSelectOption = () => {

        setPage(1);

        var category = "stock-name";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {

                setOptionCategoryArray(response.data);
                setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

        var category = "PRODUCT-NAME";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {

                setOptionCategoryArrayProduct(response.data);

            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getSelectOptionTransfer = () => {

        setPage(1);

        var category = "Stock-transfer-type";
        SelectOptionService.getAllSelectedOptionsTransferType(category)
            .then((response) => {


                setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

        var category2 = "DESCRIPTION-TYPE";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {

                setOptionCategoryArrayDescription(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(retrieveData, [page, pageSize]);

    const refreshList = () => {
        setPage(1);
        retrieveData();
    };



    const findByTitle = () => {
        setPage(1);
        retrieveData();
    };

    const openObject = (id) => {
        setPage(1);
        setcurrentData(initialDataState);
        props.history.push("/stock-level/" + id);
    };

    const openObjectTransfer = (id) => {
        setPage(1);
        setcurrentData(initialDataState);
        props.history.push("/stock-level-transfer/" + id);
    };

    const openObjectDelete = (id) => {
        setPage(1);
        setcurrentData(initialDataState);
        props.history.push("/stock-level-delete/" + id);
    };

    const openObjectWithProduct = (rowIndex) => {
        setcurrentData(initialDataState);

        const id = tutorialsRef.current[rowIndex].id;

        props.history.push("/stock-with-products/" + id);
    };

    const updateData = () => {
        setPage(1);
        StockService.update(currentData.id, currentData)
            .then(response => {
                //console.log(response.data);
                setMessage("The Data was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });

    };

    const deleteData = (rowIndex) => {
        setPage(1);
        const id = tutorialsRef.current[rowIndex].id;

        StockService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const stockOut = () => {
        props.history.push("/stock-out");
    }

    const list = () => {
        props.history.push("/stock-in");
    }

    const stockLevel = () => {
        props.history.push("/stock-level");
    }


    const stockInitial = () => {
        props.history.push("/stock-initial");
    }

    const regacy = () => {
        setPage(1);
        StockService.getLegacy();
    }

    const [lgShow, setLgShow] = useState(false);

    const getProductNameQty = () => {
        setPage(1);

        var data = {
            text1: optionCategorySelectedValue,
            text2: optionCategorySelectedValueProduct
        }


        StockService.getProductNameQty(data)
            .then(response => {
                //console.log(response.data);
                setMessage(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }


    return (<>
        <div className='pages'>

            <div className="row">



                {/* cards */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>


                                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>

                                {isManager ? (<td><div className="col-md-8 form-group">
                                    <select className="form-control select-option" onChange={handleChangeoptionCategories} >
                                        <option>Select Branch</option>
                                        {optionCategoryArray.map((optionCategory) => (
                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                        ))}

                                    </select>
                                </div></td>) : (<td><div className="col-md-8 form-group">
                                    <select disabled className="form-control select-option" onChange={handleChangeoptionCategories} >
                                        <option>{memberdetails.account}</option>
                                        {optionCategoryArray.map((optionCategory) => (
                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                        ))}

                                    </select>
                                </div></td>)}

                                <td> <button onClick={retrieveDataBystock} className="button-tools">OK</button> </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="col-md-8 form-group"><select className="form-control select-option" onChange={handleChangeoptionDescription} >
                                        <option>Select Type</option>
                                        {optionCategoryArrayDescription.map((optionCategory) => (
                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                        ))}

                                    </select> </div> </td>
                                <td ></td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                {/* <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <tbody>

                            <tr>
                                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>

                                {isManager ? (<td><div className="col-md-8 form-group">
                                    <select className="form-control select-option" onChange={handleChangeoptionCategories} >
                                        <option>Select</option>
                                        {optionCategoryArray.map((optionCategory) => (
                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                        ))}

                                    </select>
                                </div></td>) : (<></>)}

                                {isManager ? (<td> <button onClick={retrieveDataBystock} className="button-tools">OK</button> </td>) : (<></>)}

                            </tr>

                            {isManager ? (<tr>
                                <td> <input onChange={onChangeSearchTitle} id="customer" type="text" name="customer" placeholder="Code" className="search-input" /> </td>
                                <td ><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                            </tr>) : (<></>)}


                        </tbody>

                    </table>

                </div> */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                {/* <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td> */}
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Supply</button></td>
                                <td className="tg-0lax"><button onClick={stockInitial} className="button-tools">Initial</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
                                <td className="tg-0lax"><button onClick={stockOut} className="button-tools">Out</button></td>
                                <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">Product</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">
                    <div className="row pagination-box">

                        <div className="col-md-3 pagination-box">{"Items per Page : "}
                            <select onChange={handlePageSizeChange} value={pageSize}>
                                {pageSizes.map((size) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select></div>

                        <div className="col-md-3 table-title"> ACTUAL STOCK </div>

                        <div className="col-md-4"><Pagination

                            count={count}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                        />

                        </div>



                    </div>
                    <div className="details">


                        <div className="table-wrap">
                            <table className="table ">
                                <thead className="scroll-thead">
                                    <tr>

                                        <th>ID</th>
                                        <th>STAND</th>
                                        <th>PID</th>
                                        <th>CODE</th>
                                        <th>PRODUCT</th>
                                        <th>DESCRIPTION</th>
                                        <th>COLOR</th>
                                        <th>CBM</th>
                                        <th>KG</th>
                                        <th>PACKAGE</th>
                                        <th>CURRENT QTY</th>
                                        <th>VALUE</th>
                                        <th>TOTAL</th>
                                        <th>TOOLS</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {tutorials &&
                                        tutorials.map((tutorial, index) => (

                                            <tr key={tutorial.id}>
                                                <td>{tutorial.id}</td>
                                                <td>{tutorial.stockname}</td>
                                                <td>{tutorial.pid}</td>
                                                <td>{tutorial.code}</td>
                                                <td>{tutorial.name}</td>
                                                <td><span className={"status " + tutorial.description}>{tutorial.description}</span></td>
                                                <td>{tutorial.color}</td>
                                                <td>{tutorial.cbm}</td>
                                                <td>{tutorial.kg}</td>
                                                <td>{tutorial.packaging}</td>
                                                <td>{tutorial.current_stock_qty}</td>
                                                <td>{tutorial.price}</td>
                                                <td>{tutorial.price * tutorial.current_stock_qty}</td>
                                                <td><div className="row">

                                                    <button
                                                        type="button" className="table-buttons col-md-1"
                                                        onClick={() => openObject(tutorial.id)}
                                                    > <AiTwotoneEdit />
                                                    </button>

                                                    {/* <button
                                                        type="button" className="table-buttons col-md-1"
                                                        onClick={() => openObjectTransfer(tutorial.id)}
                                                    > <MdTransform />
                                                    </button> */}

                                                    <button
                                                        type="button" className="table-buttons col-md-1"
                                                        onClick={() => openObjectDelete(tutorial.id)}
                                                    > <AiFillDelete />
                                                    </button>

                                                </div></td>
                                            </tr>

                                        ))}
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <Modal
            size="md"
            // backdrop="static"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

        >

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">


                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-bg">

                <div className="dataRow">

                    <center>

                        <div className="details-sm-2">
                            <center>
                                <div className="row"><select className="form-control select-option" onChange={handleChangeoptionCategories} >
                                    <option>STAND</option>
                                    {optionCategoryArray.map((optionCategory) => (
                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                    ))}

                                </select></div>
                                <hr />

                                <div className="row"><select className="form-control select-option" onChange={handleChangeoptionCategoriesProduct} >
                                    <option>PRODUCT</option>
                                    {optionCategoryArrayProduct.map((optionCategory) => (
                                        <option key={optionCategoryArrayProduct.value}>{optionCategory.value}</option>
                                    ))}

                                </select></div>
                                <hr />
                                Qty:{message}
                                <hr />
                                <div className="row"><button onClick={getProductNameQty} className="button-profile">Ok</button></div>

                            </center>

                        </div>

                    </center>





                </div>


            </Modal.Body>
        </Modal>
    </>
    );
});

export default StockListLevel;