import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { ImCancelCircle } from "react-icons/im";
import { FcServices } from 'react-icons/fc';
import { GrFormSearch, GrStatusGood } from 'react-icons/gr';

import { AiFillFolderOpen } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import OrderService from "../../services/order.service";
import SelectOptionService from "../../services/select-options.service";
import ProductsService from "../../services/products.service";
import MemberService from "../../services/members.service";
import AccessControlOtpService from "../../services/access-control-otp.service";
import { format } from 'date-fns';



const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};



const SalesOrderNew = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_SALER")) {
    return <Redirect to="/unauthorized" />;

  }


  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);
  const [lgShow5, setLgShow5] = useState(false);


  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: 0,
    size: "",
    color: "",
    code: "",
    name: "",
    price: 0,
    min_price: 0,
    max_price: 0,
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",
    username: "",
    location: "",
    product_price: "",
    description: ""


  };



  const [tutorial, setData] = useState(initialDataState);
  const [isOrderDataEmpty, setIsOrderDataEmpty] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [sallerName, setSallerName] = useState("");
  const [messageOnSave, setMessageOnSave] = useState("");
  const [messageAuthorization, setMessageAuthorization] = useState("");
  //ANALYSIS
  const [orderNoAnalysis, setOrderNoAnalysis] = useState("");
  const [noOfItemAnalysis, setNoOfItemAnalysis] = useState("");
  const [totalAnalysis, setTotalAnalysis] = useState("");

  const [orderNoAnalysis2, setOrderNoAnalysis2] = useState("");
  const [noOfItemAnalysis2, setNoOfItemAnalysis2] = useState("");
  const [totalAnalysis2, setTotalAnalysis2] = useState("");
  const [sallerAnalysis2, setSallerAnalysis2] = useState("");
  const [customerAnalysis2, setCustomerAnalysis2] = useState("");
  const [isOrderSavedAnalysis2, setIsOrderSavedAnalysis2] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authorizedBy, setAuthorizedBy] = useState(false);
  const [authorizedBranch, setAuthorizedBranch] = useState(false);
  const [authorization_required, setAuthorization_required] = useState("");
  const [priceAuthorizationCode, setPriceAuthorizationCode] = useState("");
  const [selectedRowForAuthorization, setSelectedRowForAuthorization] = useState("");


  const [orderTotal, setOrderTotal] = useState(0);
  const [isIdGenerated, setIsIdGenerated] = useState(false);
  const [isOrderVerfied, setIsOrderVerfied] = useState(false);
  const [avairableQty, setAvairableQty] = useState(0);
  const [isAvairableQtyChecked, setIsAvairableQtyChecked] = useState(false);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [memberdetails, setMemberDetails] = useState([]);
  const [isManager, setIsManager] = useState(false);

  const [stockName, setStockName] = useState("");

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeAuthorization = (e) => {

    const code = e.target.value;
    setPriceAuthorizationCode(code);
    //console.log("======147====" + code);

  };



  const saveData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setStockName(optionCategorySelectedValue);

    } else {
      setStockName(currentUser.account);
    }

    var data = {
      // stand: stockName,
      id: null,
      orderid: orderNo,
      orderTime: tutorial.orderTime,
      product: currentData.name,
      code: currentData.code,
      pid: currentData.id,
      qty: tutorial.qty,
      size: currentData.size,
      color: currentData.color,
      price: tutorial.max_price,
      saller: sallerName,
      customer: customerName, //from customer search page
      phone: customerPhone,   //from customer search page
      salesStatus: "TEMPORARY",
      stand:memberdetails.account ,
      username: currentUser.username,
      location: memberdetails.account,

      min_price: currentData.min_price,
      authorization_status: "",
      authorization_by: "",
      product_price: currentData.max_price,
      description:currentData.description


    };

    if (data.customer == null || data.customer == "") {
      setMessage("Customer name missing!");
    } else if (data.phone == null || data.phone == "") {
      setMessage("Customer phone number missing!");
    }
    else if (data.pid === null || data.pid === "") {
      setMessage("Product details are missing!");
    } else if (data.stand === null || data.stand === "" || data.stand == "Select") {
      setMessage("Select valid stock or stand!");
    } else if (data.qty === null || data.qty === "") {
      setMessage("Invalid Quantity!");

    } else if (data.qty === 0) {
      setMessage("Invalid Quantity!");

    } else if (data.price === 0) {
      setMessage("Invalid Price!");

    }

    else if (isAvairableQtyChecked === false) {
      setMessage("Check if product is avairable!");

    } else if (avairableQty < data.qty) {
      setMessage("We don't have this product!");
    } else if (avairableQty < 1) {
      setMessage("We don't have this product!");
    }

    // if (currentData.min_price+1 > data.price && isAuthorized === false) {
    //   console.log("===202=== You can't sale on this price" + currentData.min_price);
    //   setLgShow2(true);
    // }

    else {



      OrderService.create(data)
        .then(response => {


          setMessage(response.data);
          //console.log(response.data);
          //refreshList();
          newData();
          retrieveOrderedData();
        })
        .catch(e => {
          console.log(e);
        });

    }
    setMessage("");
    setIsAvairableQtyChecked(false);
    setAvairableQty(0);



  };

  const newData = () => {
    setPage(1);
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const reset = () => {

    props.history.push("/sales-order-customer");

  };

  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };


  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveOrderedData = () => {

    setPage(1);
    OrderService.getOrderByNoTemp(orderNo)
      .then((response) => {

        const { tutorials } = response.data;

        setOrderedData(tutorials);
        setIsOrderDataEmpty(false);


      })
      .catch((e) => {
        console.log(e);
      });

    //get current order total
    OrderService.getOrderByNoTotal(orderNo)
      .then((response) => {


        setOrderTotal(response.data);


        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

  };

  const generateOrderNo = () => {
    setPage(1);
    //get order no
    OrderService.getOrderNo(currentUser.username)
      .then((response) => {


        setOrderNo(response.data);
        setIsIdGenerated(true);



      })
      .catch((e) => {
        console.log(e);
      });

    //end get order no

  }

  const retrieveData = (searchType) => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize, searchType);

    ProductsService.getAll(params)
      .then((response) => {
        const { tutorials } = response.data;

        setDatas(tutorials);


        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getMemberData();

    //get customer details
    /////get customer details 
    const queryParams = new URLSearchParams(window.location.search);
    const name1 = queryParams.get('name');
    const phone = queryParams.get('phone');
    const saller = queryParams.get('saller');

    setCustomerName(name1);
    setCustomerPhone(phone);
    setSallerName(saller);


  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

      if (currentUser.roles.includes("ROLE_MANAGER")) {
        setIsManager(true);
  
      }

  }

  const getSelectOption = () => {
    setPage(1);
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };



  const findByTitle = (code) => {
    setPage(1);
    retrieveData(code);
  };

  const findByTitleName = (name) => {
    setPage(1);
    const params = getRequestParams(searchTitle, page, pageSize);

    ProductsService.getAllSearchWithName(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openObject = (id) => {
    setPage(1);
    setIsAvairableQtyChecked(false);
    setAvairableQty(0);
    setMessage("");

    setcurrentData(initialDataState);

    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const updateData = () => {
    OrderService.update(currentData.id, currentData)
      .then(response => {
        //console.log(response.data);
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });

  };

  const deleteData = (rowIndex) => {
    setPage(1);

    OrderService.remove(rowIndex)
      .then((response) => {
        /// props.history.push("/product");

        retrieveOrderedData(orderNo);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };





  const payment = (data) => {
    props.history.push("/payment-historic?data=" + data);
  }

  const pendingOrder = (data) => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const confirmnewSale = () => {
    setPage(1);

    if (orderTotal == 0 || orderTotal == null) {

      setSubmitted(true);

      setMessageOnSave("You can't save empty order!");

    } else {

      //check if no unauthorized low price



      OrderService.checkIfRowpriceExist(orderNo).then(response => {



        if (response.data > 0) {

          console.log("you can't save" + response.data);
          setLgShow3(true);


        } else {

          OrderService.saveNewSale(orderNo).then(response => {

            if (response.data > 0) {
              // props.history.push(`/sales-order-success/?id=${orderNo}`);
              checkIfOrderWasSaved();

            }

            if (response.data < 1) {
              setMessage("This orde was not saved! Click on save.");

            }

          })
            .catch(e => {
              console.log(e);
            });

        }


      })
        .catch(e => {
          console.log(e);
        });







    }

  }

  const checkIfOrderWasSaved = () => {

    OrderService.checkeOfOrderWasSaved(orderNo).then(response => {

      const { orderNoAnalyis, noOfItemsAnalysis, totalAnalysis, saller, customer } = response.data;
      var x = 5;
      setOrderNoAnalysis2(orderNoAnalyis);
      setNoOfItemAnalysis2(noOfItemsAnalysis);
      setTotalAnalysis2(totalAnalysis);
      setCustomerAnalysis2(customer);
      setSallerAnalysis2(saller);

      setSmShow(true);

      if (noOfItemsAnalysis > 0) {
        setIsOrderSavedAnalysis2(true);
      } else {
        setIsOrderSavedAnalysis2(false);
      }




    })
      .catch(e => {
        console.log(e);
      });


  }

  const checkQty = () => {
    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setStockName(optionCategorySelectedValue);

    } else {
      setStockName(memberdetails.account);
    }
 
    var data = {
      text1: currentData.id,
      text2: memberdetails.account
    }


    ProductsService.getAvairableProductQty(data)
      .then(response => {

        setIsAvairableQtyChecked(true);

        setAvairableQty(response.data);
        setMessage("Qty in stock" + response.data);

      })
      .catch(e => {
        console.log(e);
        setMessage("We don't have this product");
      });


  }

  const analyse = () => {

    setMessage("");

    OrderService.saveNewSaleAnalysis(orderNo)
      .then(response => {

        const { orderNoAnalyis, noOfItemsAnalysis, totalAnalysis, authorization_required } = response.data;

        setOrderNoAnalysis(orderNoAnalyis);
        setNoOfItemAnalysis(noOfItemsAnalysis);
        setTotalAnalysis(totalAnalysis);
        setAuthorization_required(authorization_required);

        if (noOfItemsAnalysis > 0) {
          setIsOrderVerfied(true);
        }

      })
      .catch(e => {
        console.log(e);

      });


  }



  const openAuthorizeRowPrice = (id, status) => {
    setPage(1);

    // console.log(id+" "+status);
    setSelectedRowForAuthorization(id);

    if (status === "NORMAL-PRICE") {
      setLgShow5(true);
    } else {
      setLgShow4(true);
    }



  };

  const authorizeLowPrice = () => {

    setMessageAuthorization("Incorrect code!");

    //console.log("ID:"+selectedRowForAuthorization+" code:"+priceAuthorizationCode);

    AccessControlOtpService.checkIfAuthorizationCodeExist(priceAuthorizationCode)
      .then((response) => {

        const { isAuthorized, authorizedBy, authorizedBranch } = response.data;

        //console.log("ID:"+selectedRowForAuthorization+" code:"+priceAuthorizationCode+" response:"+response.data);

        if (isAuthorized === 1 & memberdetails.account === authorizedBranch) {

          var data = {
            text1: selectedRowForAuthorization,
            text2: authorizedBy
          }

          OrderService.authorizeLowPrice(data)
            .then(response => {

              setMessageAuthorization(response.data);


              retrieveOrderedData();

            })
            .catch(e => {
              console.log(e);

            });


        }


      })
      .catch((e) => {
        console.log(e);
      });

  }


  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <div className="table-account">
                <tbody>

                  <tr>
                    <td>ORDER: {orderNoAnalysis}</td>
                  </tr>
                  <tr>
                    <td>ITEMS: {noOfItemAnalysis}</td>
                  </tr>
                  <tr>
                    <td>AMOUNT: {totalAnalysis}</td>
                  </tr>

                  <tr>
                    <td>AUTHORIZATION: {authorization_required}</td>
                  </tr>

                </tbody>
              </div>
            </table>

          </div>


          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={pendingOrder} className="button-tools">Pending</button></td>
                  {isOrderVerfied ? (<td></td>) : (<td className="tg-0lax">{isIdGenerated ? (<Button onClick={() => setLgShow(true)} className="button-tools-red">Add</Button>) : (<button className="button-tools-red" onClick={generateOrderNo}> Add </button>)}  </td>)}

                </tr>
                <tr>
                 
                  <td className="tg-0lax"><button onClick={depositOrder} className="button-tools">Credit</button></td>
                </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div id="wrapper">

                {isOrderDataEmpty ? ("") : (<div id="c1">



                  {isOrderVerfied ? (<button className="button-tools-red" onClick={confirmnewSale}>Save</button>) : (<button className="button-form" onClick={analyse}>Analyse</button>)}

                </div>)}

                <div id="c2"> Total: {orderTotal} </div>
                <div className="danger-message">{message}</div>
              </div>


            </div>
            <div className="details">


              <div className="table-wrap">

                <table className="table">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Stand</th>
                      <th>Code</th>
                      <th>pid</th>
                      <th>size</th>
                      <th>color</th>
                      <th>price</th>
                      <th>Qty</th>
                      <th>Total</th>
                      <th>Discount</th>
                      <th>Authorization</th>
                      <th>Action</th>
                    </tr>

                  </thead>


                  <tbody>
                    {orderedData &&
                      orderedData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.description}</td>
                          <td>{tutorial.stand}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.pid}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.size}</td>
                          <td>{tutorial.price}</td>
                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total}</td>
                          <td>{tutorial.product_price - tutorial.price}</td>
                          <td><span className={"status " + tutorial.authorization_status}>{tutorial.authorization_status}</span></td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => deleteData(tutorial.id)}
                            >
                              <ImCancelCircle />
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openAuthorizeRowPrice(tutorial.id, tutorial.authorization_status)}
                            >
                              <GrStatusGood />
                            </button>

                          </div></td>
                        </tr>
                      ))}
                  </tbody>




                </table>


              </div>

            </div>

          </div>
        </div>


      </div>


      <Modal
        size="xl"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-input-text"
                  value={searchTitle}
                  onChange={onChangeSearchTitle}

                />


              </div>

              <div className="col-md-8">
                <button onClick={findByTitle} className="button-form-sm"><span><GrFormSearch />Code</span></button>
                <button onClick={findByTitleName} className="button-form-sm"><span><GrFormSearch />Product</span></button>

              </div>

            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow-with-two-column-modal">
            <div className="details">
              <div className="table-wrap">

                <table className="table">
                  <thead>
                    <tr>

                      <th>CODE</th>
                      <th>PRODUCT</th>
                      {/* <th>min</th> */}
                      <th>PRICE</th>
                      <th>PID</th>
                      <th>COLOR</th>
                      <th>SIZE</th>
                      <th>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.name}</td>
                          {/* <td>{tutorial.min_price}</td> */}
                          <td>{tutorial.max_price}</td>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.size}</td>
                          <td><div className="table-action-button">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <AiFillFolderOpen />
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>
              </div>
            </div>
            <div className="details">

              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">



                    <div className="col-md-4">
                      <label>Product</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Item name" onChange={handleInputChange} value={currentData.name} />
                    </div>

                    <div className="col-md-4">
                      <label>Description</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="description" className="form-control" name="description" placeholder="Description" onChange={handleInputChange} value={currentData.description} />
                    </div>

                    <div className="col-md-4">
                      <label>CODE</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChange} value={currentData.code} />
                    </div>

                    {/* <div className="col-md-4">
                      <label>size</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChange} value={currentData.size} />
                    </div> */}

                    <div className="col-md-4">
                      <label>Price</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product_price" className="form-control" name="product_price" placeholder="Max-price" onChange={handleInputChange} value={currentData.max_price} />
                    </div>

                    <div className="col-md-4">
                      <label>Color</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChange} value={currentData.color} />
                    </div>
                        
                    {isManager ? (<><div className="col-md-4">
                      <label>Stock</label>
                    </div>
                  
                    <div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></>):(<><div className="col-md-4">
                      <label>Stock</label>
                    </div>
                   
                    <div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option>{memberdetails.account}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></>)}

                    

                    

                    <div className="col-md-4">
                      <label>PRICE</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="number" id="max_price" className="form-control" name="max_price" placeholder="Price" onChange={handleInputChange} value={tutorial.max_price} />
                    </div>


                    <div className="col-md-4">
                      <label>QTY</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="number" id="qty" className="form-control" name="qty" placeholder="QTY" onChange={handleInputChange} value={tutorial.qty} />
                    </div>



                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">

                    </div>

                    <div className="col-md-8 form-group">
                      {isAvairableQtyChecked ? (<button onClick={saveData}
                        type="button" className="button-tools-red">  Add</button>) : (<button onClick={checkQty}
                          type="button" className="button-form">Check</button>)}



                    </div>


                  </div>
                </div>
              </form>

            </div>

          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">


              <div className="col-md-2">
                <FaIcons.FaShoppingCart />
              </div>


            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">
            <div className="details-dialog-sm">

              <table className="table table2">
                <tbody>
                  <tr>
                    <td>
                      Order: {orderNoAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Items: {noOfItemAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Total: {totalAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Saller: {sallerAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Customer: {customerAnalysis2}
                    </td>
                  </tr>
                </tbody>
              </table>

              <center>

                <div className="row"> <button onClick={pendingOrder} className="button-form">Close</button> </div>
                <div className="row"> <button onClick={confirmnewSale} className="button-tools-red">Save</button> </div>
                {/* {isOrderSavedAnalysis2 ?(""):(<div className="row"> <button onClick={confirmnewSale} className="button-tools-red">Save</button> </div>)}  */}

              </center>

            </div>
          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Warning

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">



                <div className="row">
                  <label className="danger-message"> This product can't be sold on this price! </label>
                </div>



                <div className="row">
                  <button onClick={() => setLgShow2(false)}
                    type="button" className="button-tools-red"> Close</button>

                </div>

              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        backdrop="static"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">


              <div className="col-md-2">
                <FaIcons.FaShoppingCart />
              </div>


            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">
            <div className="details-dialog-sm">

              <table className="table table2">
                <tbody>
                  <tr>
                    <td>
                      Order: {orderNoAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Items: {noOfItemAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Total: {totalAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Saller: {sallerAnalysis2}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Customer: {customerAnalysis2}
                    </td>
                  </tr>
                </tbody>
              </table>

              <center>

                <div className="row"> <button onClick={pendingOrder} className="button-form">Close</button> </div>
                <div className="row"> <button onClick={confirmnewSale} className="button-tools-red">Save</button> </div>
                {/* {isOrderSavedAnalysis2 ?(""):(<div className="row"> <button onClick={confirmnewSale} className="button-tools-red">Save</button> </div>)}  */}

              </center>

            </div>
          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Authorization

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm danger-message">

                You have unauthorized LOW-PRICE


              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow4}
        onHide={() => setLgShow4(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Authorize low price

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>



              <div className="row">
                <hr />
                <label>Enter Code</label>
              </div>
              <div className="row form-group">
                <input type="text" id="priceAuthorizationCode" className="form-control" name="priceAuthorizationCode" placeholder="Authorization code" onChange={handleInputChangeAuthorization} />
              </div>
              <hr />
              <div className="row danger-message"><center>{messageAuthorization}</center> </div>
              <div className="row"> <button onClick={authorizeLowPrice} className="button-tools-red">Ok</button> </div>

              <div className="row"> <button onClick={() => setLgShow4(false)} className="button-form">Close</button> </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow5}
        onHide={() => setLgShow5(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Authorize low price

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>




              <hr />
              <div className="row danger-message"><center>Not needed.</center> </div>
              <hr />

              <div className="row"> <button onClick={() => setLgShow5(false)} className="button-form">Close</button> </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>


    </>
  );
});

export default SalesOrderNew;