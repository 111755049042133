import axios from "axios";

export default axios.create({

//  baseURL: "http://localhost:9115/api",
 baseURL: "https://furniture.anandaapp.com:9115/api",
  headers: {
    "Content-type": "application/json"
  }
  
});
