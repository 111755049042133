import React, { useState, useEffect, useRef, useMemo } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import DatePicker from 'react-datepicker';
import logo from '../images/logo.png'

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import StockService from "../../services/stock.service";


const currentUser = AuthService.getCurrentUser();


const Home = withRouter((props) => {

  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  } else {
    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

      return <Redirect to="/login" />;

    }

  }



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [memberdetails, setMemberDetails] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const retrieveData = () => {
    setPage(1);

    getMemberData();

    createStockInitial();

  };

  const createStockInitial = () => {

    StockService.createStockInitial()
      .then((response) => {



        console.log("=======70=======" + response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);

        if (response.data.disabled === "YES") {
          props.history.push("/unauthorized-disabled");
        }

      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);


  const home = () => {
    props.history.push("/home/");
  }
  const customers = () => {
    props.history.push("/customers/");
  }

  const products = () => {
    props.history.push("/products/");
  }
  const reports = () => {
    props.history.push("/reports/");
  }
  const salesPendingOrder = () => {
    props.history.push("/sales-pending-order/");
  }

  const userList = () => {
    props.history.push("/users/");
  }
  const salesList = () => {
    props.history.push("/sales-list/");
  }

  const expenses = () => {
    props.history.push("/expense-list/");
  }

  const stock = () => {
    props.history.push("/stock-in/");
  }


  const settings = () => {
    props.history.push("/settings/");
  }
  const stockKeeper = () => {
    props.history.push("/stockkeeper-pending-request/");
  }


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}


        {/* end card box */}



        <div className="dataRow">

          <center>
            <div className="row">
            <div className="col-md-3"> 
              <img src={logo} id="icon" alt="User Icon" className="home-img"/>
            </div>
              
              <div className="col-md-6"> <div className="details-home">
              <div className="row"><button onClick={reports} className="button-tools-home">Reports</button></div>
              <div className="row"><button onClick={customers} className="button-tools-home">Customers</button></div>
              <div className="row"><button onClick={products} className="button-tools-home">Products</button></div>
              <div className="row"><button onClick={salesList} className="button-tools-home">Sales</button></div>
              <div className="row"><button onClick={salesPendingOrder} className="button-tools-home">Orders</button></div>
              <div className="row"><button onClick={expenses} className="button-tools-home">Expenses</button></div>
              <div className="row"><button onClick={userList} className="button-tools-home">Users</button></div>
              <div className="row"><button onClick={stock} className="button-tools-home">Stock</button></div>
              <div className="row"><button onClick={settings} className="button-tools-home">Settings</button></div>
              {/* <div className="row"><button onClick={stockKeeper} className="button-tools-home">StockKeeper</button></div> */}

            </div> </div>
            </div>
           
          </center>

        </div>
      </div>

    </div>
  );
});

export default Home;
