import http from "./http-common";
import authHeader from "../services/auth-header";

const getAll = (params) => {
  return http.get("/stock", { params,headers: authHeader() });
};

const getAllIn = (params) => {
  return http.get("/stockinlist", { params,headers: authHeader() });
};



const get = (id) => {
  return http.get(`/stock/${id}`,{ headers: authHeader() });
};

const create = (data) => {
  return http.post("/stock", data,{ headers: authHeader() });
};

const createRetuned = (data) => {
  return http.post("/stockreturned", data,{ headers: authHeader() });
};

const createStockOut = (data) => {
  return http.post("/stockout", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/stock/${id}`, data,{ headers: authHeader() });
};

const updateReturned = (id, data) => {
  return http.put(`/stockreturned/${id}`, data,{ headers: authHeader() });
};

const updateStockOut = (id, data) => {
  return http.put(`/stockout/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/stock/${id}`,{ headers: authHeader()});

  
};

///////////////////stock in/////////////////////////////////

const getStockEntryCode = () => {
  
  return http.get("/stockentrycode", { headers: authHeader() });
};

const getStockIn = (data) => {
  return http.post("/stockin", data,{ headers: authHeader() });
};

const getStockInInspection = (data) => {
  return http.post("/stockininspection", data,{ headers: authHeader() });
};

const getStockInSearch = (data) => {
  return http.post("/stockinsearch", data,{ headers: authHeader() });
};

const getStockInSearchWithName = (data) => {
  return http.post("/stockinsearchwithname", data,{ headers: authHeader() });
};

const getByStockAndDate = (data) => {
  return http.post("/getbystockanddate", data,{ headers: authHeader() });
};

/////////////////////stock level //////////////////////////////

const deleteStockLevel = (id) => {
  return http.delete(`/stocklevel/${id}`,{ headers: authHeader()});

  
};

const getAllStockLevel = (params) => {
  return http.get("/stocklevel", { params,headers: authHeader() });
};

const getAllStockLevelBystock= (params) => {
  return http.get("/stocklevelbystock", { params,headers: authHeader() });
};

const getAllStockLevelBystockByDescription= (data) => {
  return http.post("/stocklevelbystockbydescription", data, { headers: authHeader() });
};

const updateStockLevel = (id, data) => {
  return http.put(`/stocklevel/${id}`, data,{ headers: authHeader() });
  
};

const getStockLevelById = (id) => {
  return http.get(`/stocklevel/${id}`,{ headers: authHeader() });
};


/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const getLegacy = () => {
  return http.get(`/legacy`,{ headers: authHeader() });
};

const getProductActualStock = (data) => {
  return http.post("/getstocklevel", data,{ headers: authHeader() });
};

const getProductNameQty = (data) => {
  return http.post("/getstocklevelbyproductname", data,{ headers: authHeader() });
};


const confirmation = (data) => {
  return http.post("/confirmation", data,{ headers: authHeader() });
};


const confirmationStockOut = (data) => {
  return http.post("/confirmationout", data,{ headers: authHeader() });
};
const getStockInitial = (data) => {
  return http.post("/getstockinitial", data,{ headers: authHeader() });
};

const getStockInitialSearch = (data) => {
  return http.post("/getstockinitialsearch", data,{ headers: authHeader() });
};
const createStockInitial = () => {
  
  return http.get("/createstockinitial", { headers: authHeader() });
};

const stockTransfer = (data) => {
  return http.post("/stocktransfer", data,{ headers: authHeader() });
 };

const StockService = {
  getAll,
  get,
  create,
  createRetuned,
  update,
  updateReturned,
  updateStockLevel,
  remove,
  findByTitle,
  //stock in
  getAllIn,
  getStockEntryCode,
  getStockIn,
  getStockInInspection,
  getStockInSearch,
  getStockInSearchWithName,
  getByStockAndDate,

  //stock level
  getAllStockLevel,
  deleteStockLevel,
  getProductNameQty,
  getAllStockLevelBystock,
  getAllStockLevelBystockByDescription,
  updateStockOut,
  createStockOut,
  getStockLevelById,
  getLegacy,
  getProductActualStock,
  confirmation,
  confirmationStockOut,
  getStockInitial,
  createStockInitial,
  getStockInitialSearch,
  stockTransfer
  
};

export default StockService;
