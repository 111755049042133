import http from "./http-common";
import authHeader from "./auth-header";

const salesReport = (data) => {
   
  return http.post("/salesreport", data,{ headers: authHeader() });
};

const salesReportProfit = (data) => {
   
  return http.post("/salesreportprofit", data,{ headers: authHeader() });
};

const cashingReport = (id) => {
   
  return http.get(`/cashingreport/${id}`, { headers: authHeader() });
};

const createHeadCashierReport = (data) => {
   
  return http.post("/headcashierreport", data,{ headers: authHeader() });
};

const getHeadCashierReport = (data) => {
   
  return http.post("/getheadcashierreport", data,{ headers: authHeader() });
};

const getHeadCashierReportTotal = (data) => {
   
  return http.post("/getheadcashierreporttotal", data,{ headers: authHeader() });
};

const ReportService = {
  
  salesReport,
  cashingReport,
  createHeadCashierReport,
  getHeadCashierReport,
  getHeadCashierReportTotal,
  salesReportProfit
  
};

export default ReportService;
